import React from "react";
import { FormGroup, Input, Label } from "reactstrap";
import logo from "../../images/logo-ringo.png";
import EditPacketTitle from '../../images/123.jpg'
import "./ringo.scss";
import moment from 'moment';
import {DOC_TYPE} from "../../constants";
import Rounding from "../../utils/rounding";
class PrintPackages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      billCreator: {}
    }
  }
  formatNumber (num) {
    if (num) {
      return new Intl.NumberFormat().format(num)
    }
    return num || ''
  }
  formatTime(date) {
    return moment(date).format('DD/MM/YYYY HH:mm')
  }
  sumPrice (a, b) {
    a = parseInt(a) || 0
    b = parseInt(b) || 0
    return new Intl.NumberFormat().format(a + b)
  }
  render() {
    const {billData = {}} = this.props;
    const groupTypeOption2 = [
      {
        label: "Hàng liên quan đến thịt - trứng - sữa - hạt giống",
        value: "Hàng liên quan đến thịt - trứng - sữa - hạt giống",
      },
      {
        label: "Hàng yến",
        value: "Hàng yến",
      },
      {
        label: "Hàng có pin - cồn - cháy nổ",
        value: "Hàng có pin - cồn - cháy nổ",
      },
      {
        label: "Hàng thương hiệu",
        value: "Hàng thương hiệu",
      },
      {
        label: "Hàng thực phẩm",
        value: "Hàng thực phẩm",
      },
      {
        label: "Hàng mỹ phẩm",
        value: "Hàng mỹ phẩm",
      },
      {
        label: "Hàng thuốc tây - thuốc nam - TPCN dạng viên",
        value: "Hàng thuốc tây - thuốc nam - TPCN dạng viên",
      },
      {
        label: "Hàng quần áo - đồ gia dụng",
        value: "Hàng quần áo - đồ gia dụng",
      },
      {
        label: "Hàng đông lạnh",
        value: "Hàng đông lạnh",
      },
      {
        label: "Hàng cây cảnh",
        value: "Hàng cây cảnh",
      },
      {
        label: "Hàng làm từ da động vật",
        value: "Hàng làm từ da động vật",
      }
    ];
    var packs = JSON.parse(this.props.billData.packs)
    return (
      <div className="ringo">
        <div className="modal-pickup billup">
          <div className="w50">
            <div className="modal-pickup__header">
              <div className="logo text-center">
                <img src={logo} width={150} alt="Logo"/>
              </div>
              <div className="title-bill">
                <h2>Mã RG: {billData.kgCode}</h2>
                <p>
                  <strong>Track & Trace: </strong>https://tracking.gopost.vn
                </p>
              </div>
            </div>
            <div className="wrap-table-content">
              <div className="left">
                <div className="table table-1">
                  <div className="table__header">
                    <span className="box">1</span>
                    <h2>THÔNG TIN NGƯỜI GỬI: KH{billData.customer?.id}</h2>
                  </div>
                  <div className="table__content">
                    <div className="item d-flex">
                      <label htmlFor="">Người gửi:</label>
                      <span className="text-nowrap">
                    {billData.customer?.name || billData.senderName}
                  </span>
                    </div>
                    <div className="item">
                      <div className="item-3line">
                        <label htmlFor="">Địa chỉ:</label>{billData.customer?.address || billData.senderAddress}
                      </div>
                    </div>
                    <div className="item d-flex">
                      <label htmlFor="">Tel:</label>
                      <span className="text-nowrap">
                    {billData?.customer?.phone || billData?.senderPhone}.....
                  </span>
                    </div>
                  </div>
                </div>
                <div className="table table-1">
                  <div className="table__header">
                    <span className="box">2</span>
                    <h2>THÔNG TIN NGƯỜI NHẬN:</h2>
                  </div>
                  <div className="table__content">
                    <div className="item d-flex">
                      <label htmlFor="">Người nhận:</label>
                      <span className="text-nowrap">
                   {billData.receiverName}
                  </span>
                    </div>
                    <div className="item">
                      <div className="">
                        <label htmlFor="">Địa chỉ:</label>{billData.receiverAddr1}, {billData.receiverCity}, {billData.receiverProvince} {billData.receiverPostalCode}, {billData.receiverCountry}
                      </div>
                    </div>
                    <div className="item d-flex">
                      <label htmlFor="">Tel:</label>
                      <span className="text-nowrap">{billData.receiverPhone}.
                  </span>
                    </div>
                  </div>
                </div>
                <div className="table-2">
                  <div className="item">
                    <p>Ngày giờ lấy hàng:</p>
                    <p>
                      {this.formatTime(billData.createdAt)}
                    </p>
                  </div>
                </div>
                <div className="group-table">
                  <div className="table-3 w-45 d-flex">
                    <span className="box">3</span>
                    <div className="table-3__left w-50">
                      <h2 className="text-left ml-0">QUỐC TẾ</h2>
                      <div className="list-check-box">
                        <div className="item-check">
                          <label>DHL VN</label>
                        </div>
                        <div className="item-check">
                          <label>UPSVN</label>
                        </div>
                        <div className="item-check">
                          <label>TNT VN</label>
                        </div>
                        <div className="item-check">
                          <label>FEDEX VN</label>
                        </div>
                        <div className="item-check">
                          <label>DHL SIN</label>
                        </div>
                        <div className="item-check">
                          <label>......................</label>
                        </div>
                      </div>
                    </div>
                    <div className="table-3__right w-50">
                      <h2 className="text-left ml-0">NỘI ĐỊA</h2>
                      <div className="list-check-box">
                        <div className="item-check">
                          <label>CPNEXPRESS</label>
                        </div>
                        <div className="item-check">
                          <label>48H/TIẾT KIỆM</label>
                        </div>
                        <div className="item-check">
                          <label>ĐƯỜNG BỘ</label>
                        </div>
                        <div className="item-check">
                          <label>HOẢ TỐC</label>
                        </div>
                        <div className="item-check">
                          <label>NỘI THÀNH</label>
                        </div>
                        <div className="item-check">
                          <label>......................</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-55 ml-1">
                    <div className="table-3 w-100">
                      <span className="box">4</span>
                      <div className="list-check-box d-flex flex-wrap">
                        {groupTypeOption2.map((item, index) => (
                          <div key={index}
                               className={`item-check mb-0 ml-2 ${billData?.packageInfo?.group?.includes(item?.value) ? "active" : "d-none"}`}>
                            <label>{item.label}</label>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="table-3 w-100 mt-2">
                      <span className="box position-left">5</span>
                      <h2 className="ml-0">Nội dung hàng hoá</h2>
                      <p className="item-3line">{billData.packContent || ''}
                        <br/> ..........................
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="right">
                <div className="ml-2">
                  <div className="group-table mt-0">
                    <div className="w-100">
                      <div className="table table-1">
                        <div className="table__header border-0">
                          <span className="box">6</span>
                          <h2 className="ml-0 text-center w-100">CƯỚC PHÍ</h2>
                        </div>
                        <div className="table__content">
                          <div className="item d-flex">
                            <label htmlFor="">Cước chính:</label>
                            <span className="text-nowrap">{this.formatNumber(billData.totalPrice)}.........
                        </span>
                          </div>
                          <div className="item d-flex">
                            <label htmlFor="">Ngoại vùng xa:</label>
                            <span className="text-nowrap">
                          .......................
                        </span>
                          </div>
                          <div className="item d-flex">
                            <label htmlFor="">Bảo hiểm:</label>
                            <span className="text-nowrap">
                            {this.formatNumber(billData.bhPrice)}.................
                        </span>
                          </div>
                          <div className="item d-flex">
                            <label htmlFor="">Thu hồi:</label>
                            <span className="text-nowrap">
                          ...............................
                        </span>
                          </div>
                          <div className="item d-flex">
                            <label htmlFor="">Đóng góp:</label>
                            <span className="text-nowrap">
                          ..............................................................................................
                        </span>
                          </div>
                          <div className="item d-flex">
                            <label htmlFor="">Cước USD:</label>
                            <span className="text-nowrap">
                          ..............................................................................................
                        </span>
                          </div>
                          <div className="item d-flex">
                            <label htmlFor="">Phí kiểm đếm:</label>
                            <span className="text-nowrap">
                          ..............................................................................................
                        </span>
                          </div>
                          <div className="item d-flex">
                            <label htmlFor="">Phí khác:</label>
                            <span className="text-nowrap">
                          ..............................................................................................
                        </span>
                          </div>
                          <div className="item d-flex">
                            <label htmlFor="" className="text-bold">TỔNG:</label>
                            <strong className="text-nowrap">
                              {this.sumPrice(billData.totalPrice, billData.bhPrice)}.............................................................................
                            </strong>
                          </div>
                          <div className="item item-bold">
                            <h2 className="mt-2 ml-0 mb-2 text-center">HÌNH THỨC THANH TOÁN</h2>
                            <div className="list-check-box d-flex">
                              <div className={`item-check mb-0 ${billData.paymentType === 'Tiền mặt' ? "active" : ""}`}>
                                <label>TIỀN MẶT</label>
                              </div>
                              <div
                                className={`item-check mb-0 ml-2 ${billData.paymentType === 'Chuyển khoản' ? "active" : ""}`}>
                                <label>CHUYỂN KHOẢN</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="table table-1 mb-0">
                        <div className="table__header border-0">
                          <span className="box position-right">7</span>
                        </div>
                        <div className="table__content">
                          <div className="item d-flex">
                            <label htmlFor="">Số kiện:</label>
                            <span
                              className="text-nowrap max-w-100">............{JSON.parse(billData.packs)?.length || ''}...........................
                        </span>
                            <label htmlFor="">Kiện</label>
                          </div>
                          <div className="item d-flex">
                            <label htmlFor="">Trọng lượng thực:</label>
                            <span className="text-nowrap max-w-80">
                          ......{billData.packageInfo?.weight ? billData.packageInfo?.weight : (billData.packageType === DOC_TYPE) ? billData.docWeight : this.calWeight(packs)}

                        </span>
                            <label htmlFor="">Kg</label>
                          </div>
                          <div className="item d-flex">
                            <label htmlFor="">Trọng lượng quy đổi:</label>
                            <span className="text-nowrap max-w-80">
                          ......{billData.packageInfo?.weight2 ? billData.packageInfo?.weight2 : (billData.packageType === DOC_TYPE) ? billData.docWeight : Rounding(packs.reduce(function (a, b) {
                              return a + parseFloat(b["chargedWeight"])
                            }, 0)) || ""}
                        </span>
                            <label htmlFor="">Kg</label>
                          </div>
                          <div className="item item-bold">
                            <div className="list-check-box d-flex justify-content-between">
                              <div className="item-2">
                                <h2 className="mt-2 ml-0 text-center">Dài</h2>
                                <p
                                  className="item-3line">...................................................................................................................................................</p>
                              </div>
                              <div className="item-2">
                                <h2 className="mt-2 ml-0 text-center">Rộng</h2>
                                <p
                                  className="item-3line">...................................................................................................................................................</p>
                              </div>
                              <div className="item-2">
                                <h2 className="mt-2 ml-0 text-center">Cao</h2>
                                <p
                                  className="item-3line">...................................................................................................................................................</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="wrap-table-content">
              <div className="table table-1 w-40">
                <div className="table__header border-0">
                  <span className="box position-right">8</span>
                  <h2 className="ml-0 text-center w-100">NGƯỜI GỬI</h2>
                </div>
                <div className="table__content">
                  <p
                    className="text-word">...........................................................................................</p>
                  <p className="text-center mt-2 font-8"><i>Họ tên và chữ ký người gửi hàng<br/> (Đồng ý với
                    điều khoản sử dụng dịch vụ)</i></p>
                </div>
              </div>
              <div className="table table-1 w-40">
                <div className="table__header border-0">
                  <span className="box position-right">9</span>
                  <h2 className="ml-0 text-center w-100 ">NHÂN VIÊN</h2>
                </div>
                <div className="table__content">
                  <p
                    className="text-word text-center">{billData.owner || ''}</p>
                  <p className="text-center mt-2 font-8"><i>Họ tên và chữ ký người nhân viên thực hiện</i></p>
                </div>
              </div>
            </div>
          </div>
          <div className="w50">
            <div className="page-laster text-center">
              <img src={EditPacketTitle} alt=""/>

            </div>
          </div>
        </div>
        <div className="box-footer">
          <div className="w-50 text-center">
            Hotline: 1900 4797 - Website: ringologistics.com
          </div>
          <div className="w-50 text-center">
            42/43-45 Nguyễn Minh Hoàng, P.12, Q. Tân Bình, TP.HCM
          </div>
        </div>
      </div>
    );
  }
}

export default PrintPackages;
